import React, {useEffect, useState} from 'react'
/*import {useFirebase} from 'utils/context/firebase'*/
import { useSnackbar } from 'notistack5'

const NotificationComponent = () => {
  /*const {onMessageListener} = useFirebase()*/
  const {enqueueSnackbar} = useSnackbar()

/*  useEffect(() => {
      onMessageListener
        .then((payload) => {
          enqueueSnackbar(`Notification: ${payload.notification.title} - ${payload.notification.body}`, {variant: 'info'})
        })
        .catch((err) => console.error('failed: ', err))
  }, [onMessageListener])*/

  return null
}

export default NotificationComponent
