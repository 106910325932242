import { useEffect, useState, useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
// material
import jwt_decode from 'jwt-decode'
import { styled } from '@material-ui/core/styles'
//
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import storage from 'utils/storage'
import user_api from 'utils/api/users'

import { SocketContext } from 'utils/context/socket'
import { MainContext } from 'utils/context/main'
import NotificationDialog from 'components/firebase/NotificationDialog'
import NotificationComponent from 'components/firebase/Notification'
/*import {useFirebase} from 'utils/context/firebase'*/
// import {UsersContext} from 'utils/context/users'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  width: '100px',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const socket = useContext(SocketContext)
  const navigation = useNavigate()
  const { setName, setRoom } = useContext(MainContext)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notifyToggle, setNotifyToggle] = useState(storage.getScheduleNotify() ?? false)
/*  const {getFcmToken} = useFirebase()*/
/*  const [fCMToken, setFCMToken] = useState(storage.getFCMToken() ?? null);
  const [fCMFailCount, setFCMFailCount] = useState(0);*/
  const [data, setData] = useState({
    users: [],
    online: 0,
    employees: [],
  })

/*  useEffect( async () => {
    const fetchAndStoreFcmToken = async () => {
      try {
        const user = JSON.parse(await storage.getUser())
        console.log('kuha na: ', user)
        if (!fCMToken) {
          console.log("FCM token is not available, fetching a new one");
          const newFcmToken = await getFcmToken();
  
          if (newFcmToken) {
            storage.storeFCM(newFcmToken);
            setFCMToken(newFcmToken);
            if (user) {
              const currentUserFCMToken = await user_api.get_active_fcm_token(user._id);
              const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map(tokenObj => tokenObj.fcmToken);
              if(!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
                await user_api.subscribe_fcm_token(user._id, fCMToken, 'pwa');
                console.log("Subscribed FCM token for user:", user._id);
              }else{
                console.log("Failed to subscribe user token", user._id);
              }
            }
          } else {
            console.log("Failed to fetch new FCM token");
            return;
          }
        } else {
          console.log("FCM token already exists:", fCMToken);
          if (!storage.getFCMToken()) storage.storeFCM(fCMToken);
          if (user) {
            const currentUserFCMToken = await user_api.get_active_fcm_token(user._id);
            const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map(tokenObj => tokenObj.fcmToken);
            if(!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
              await user_api.subscribe_fcm_token(user._id, fCMToken, 'pwa');
              console.log("Subscribed FCM token for user:", user._id);
            }else{
              console.log("Failed to subscribe user token", user._id);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching or storing FCM token:", error);
      }
    };

    if (notifyToggle === true) {
      const intervalId = setInterval(async () => {
        const existingToken = storage.getFCMToken()
        
        if (existingToken) {
          clearInterval(intervalId); // Stop the interval if token is already in local storage
          return;
        }
  
        if (fCMFailCount > 5) {
          clearInterval(intervalId); // Stop the interval if token is already in local storage
          return;
        }
        
        fetchAndStoreFcmToken();
        setFCMFailCount((prev) => prev + 1);
      }, 15000); // Fetch every 5 seconds
      fetchAndStoreFcmToken();
    }
  }, [fCMToken, notifyToggle])*/

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()

      if (!local_user) return navigate('/login')
      const user = JSON.parse(local_user)
      let name,
        _id,
        uuid = undefined

      if (user.role === 1 || user.role >= 3) {
        name = user.displayName
        uuid = _id = user._id
        socket.emit('connected', { name, uuid, _id }, (error) => {
          if (error) {
            console.log(error)
          }
        })
      } else {
        name = user.displayName
        _id = user.sid
        uuid = user._id
      }

      if (!name || !uuid || !_id) return
      setName(name)
      setRoom(uuid)
      socket.emit('connected', { name, uuid, _id }, (error) => {
        if (error) {
          console.log(error)
        }
      })
      return setData((p) => ({ ...p, users: user }))
    }

    const check_login = async () => {
      const token = await storage.getToken()
      const current_date = new Date()

      if (!token) {
        await storage.remove()
        return
      }

      if (jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
        await storage.remove()
        return navigation('/login')
      }

      const result = await user_api.get_user(jwt_decode(token)['id'])
      if (!result.ok) {
        await storage.remove()
        return navigation('/login')
      }

      if (result.data.isArchived) {
        return alert('Your account is disabled')
        window.location.href = '/login'
      }
      if ((result.data.role === 1 || result.data.role >= 3) && !result.data.isOnBoarded) return navigation('/store/onboard')
      if (result.data.role === 0 && !result.data.isOnBoarded) return navigation(`${result.data.store_id}/onboard`)

      if (result.data.role === 1 || result.data.role >= 3) return navigation('/stores')

    }

    check_login()
    load()
    if (!storage.getScheduleNotify()) setDialogOpen(true); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseDialog = () => {
    setDialogOpen(false);
    storage.setScheduleNotify(notifyToggle)
  };

  const handleNotifyToggle = (value) => {
    setNotifyToggle(value)
  }

  return (
    <>
      <RootStyle>
        <NotificationComponent />
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} _data={data} />
        <NotificationDialog open={dialogOpen} onClose={handleCloseDialog} toggle={notifyToggle} onToggle={handleNotifyToggle} />  
        {data.users.role && (JSON.parse(data.users.role) === 1 || JSON.parse(data.users.role) >= 3) ? (
          <DashboardSidebar isOpenSidebar={open} account={data.users} onCloseSidebar={() => setOpen(false)} />
        ) : (
          ''
        )}
        <MainStyle>
          {/* <Detector
            render={({online}) =>
              !online ? (
                <>
                  <Box
                    component="img"
                    src="/static/illustrations/offline.gif"
                    sx={{height: 300, mx: 'auto', my: {xs: 0, sm: 0}}}
                  />
                  <Typography variant="h4" sx={{textAlign: 'center'}}>
                    No Internet Connection
                  </Typography>
                </>
              ) : (
                <Outlet />
              )
            }
          /> */}
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  )
}
